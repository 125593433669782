import { render, staticRenderFns } from "./listas.vue?vue&type=template&id=502804ad&scoped=true&lang=html&"
import script from "./listas.vue?vue&type=script&lang=js&"
export * from "./listas.vue?vue&type=script&lang=js&"
import style0 from "./listas.vue?vue&type=style&index=0&id=502804ad&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502804ad",
  null
  
)

export default component.exports