<template lang="html">
  <div class="contPrincipal" v-if="open">
    <div class="nuevaCont">
      <img src="../../assets/iconos/cerrarB.png" v-on:click="cerrar" alt="">
      <p>¿Estas seguro de eliminar la lista?</p>
      <button type="submit" v-on:click="cerrar">Sí</button>
    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        open: this.$store.state.eliminarLista,
      };
    },
    components: {},
    methods: {
      cerrar: function () {

        var thisTemporal = this;

        thisTemporal.open = false;
        thisTemporal.$store.state.eliminarLista = false;
        console.log(thisTemporal.$store.state.eliminarLista);

      },
    },
    mounted: function () { },
  };
</script>

<style lang="css" scoped>
  .contPrincipal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nuevaCont {
    width: 90%;
    height: 180px;
    background-color: #bad9d8;
    border-radius: 6px;
    box-shadow: 6px 3px 16px rgba(0, 0, 0, 0.16);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .nuevaCont img
  {
    width: 15px;
    height: 15px;
    margin-bottom: 15px;
  }

  .nuevaCont input {
    width: 70%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    background: none;
    font-size: 4.5vw;
  }

  .nuevaCont button {
    width: 65%;
    height: 30px;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    margin-top: 30px;
  }
</style>