<template lang="html">

  <div class="principal">

    <nav class="navMenu">
      <button v-on:click="btnDash" class="btnAtras" type="button" name="button"></button>
      <!-- <button class="btnHamburguesa" v-on:click="btnMenu" type="button" name="button"></button> -->
    </nav>

    <eliminarProducto v-if="$store.state.eliminarProducto"></eliminarProducto>

    <div id="contMenuMovil" class="contMenuMovil">
      <button v-on:click="btnDash" class="itemMenuMovil" type="button" name="button">
        <img v-on:click="btnDash" class="imgItemMenu" src="../assets/iconos/noteW.png" alt="">
        <p v-on:click="btnDash" class="txtItemMenu">Inicio</p>
        <img v-on:click="btnDash" class="imgItemMenu" src="../assets/iconos/arrow.png" alt="">
      </button>

      <button v-on:click="btnProfile" class="itemMenuMovil" type="button" name="button">
        <img v-on:click="btnProfile" class="imgItemMenu" src="../assets/iconos/noteW.png" alt="">
        <p v-on:click="btnProfile" class="txtItemMenu">Mi perfil</p>
        <img v-on:click="btnProfile" class="imgItemMenu" src="../assets/iconos/arrow.png" alt="">
      </button>

      <button v-on:click="btnBlog" class="itemMenuMovil" type="button" name="button">
        <img v-on:click="btnBlog" class="imgItemMenu" src="../assets/iconos/noteW.png" alt="">
        <p v-on:click="btnBlog" class="txtItemMenu">Blog</p>
        <img v-on:click="btnBlog" class="imgItemMenu" src="../assets/iconos/arrow.png" alt="">
      </button>

      <!-- <button class="itemMenuMovil" type="button" name="button">
        <img class="imgItemMenu" src="../assets/iconos/noteW.png" alt="">
        <p class="txtItemMenu">Dashboard</p>
        <img class="imgItemMenu" src="../assets/iconos/arrow.png" alt="">
      </button> -->
    </div>

    <!-- <nav class="navInferior">

      <button id="btnMarket" class="btn" type="button" name="button"></button>
      <button class="btn" type="button" name="button"></button>
      <button class="btnCentro" type="button" name="button"></button>
      <button class="btn" type="button" name="button"></button>
      <button class="btn" type="button" name="button"></button>
    </nav> -->


    <div id="contAlert" class="contAlert" v-if="contAlert">
      <img v-on:click="cerrarAlert" src="../assets/iconos/cancel.png" alt="">
      <p>Nuevo Usuario</p>
      <input type="text" name="" value="">
      <button v-on:click="cerrarAlert" type="button" name="button">Sí, cambiar</button>

    </div>

    <div class="titleProfile">
      <p>Supermercado</p>
      <button type="button" name="button" v-on:click="productos">Productos</button>
    </div>


    <div class="contProfile">

      <div class="contListas">

        <div class="listas">
          <img src="../assets/aguacate.jpg" alt="">
          <div class="datosProducto">

            <div class="datosA">
              <p>Frutas</p>
              <img src="../assets/iconos/eliminar.png" v-on:click="eliminarProducto" alt="">
            </div>
            <p id="nombreProducto">Aguacate</p>
            <div class="datosB">
              <p>Cantidad: </p>
              <h3>3</h3>
            </div>

          </div>
        </div>

        <div class="listas">
          <img src="../assets/tomate.jpg" alt="">
          <div class="datosProducto">

            <div class="datosA">
              <p>Frutas</p>
              <img src="../assets/iconos/eliminar.png" v-on:click="eliminarProducto" alt="">
            </div>
            <p id="nombreProducto">Tomate</p>
            <div class="datosB">
              <p>Cantidad: </p>
              <h3>3</h3>
            </div>

          </div>
        </div>

        <div class="listas">
          <img src="../assets/queso.jpg" alt="">
          <div class="datosProducto">

            <div class="datosA">
              <p>Frutas</p>
              <img src="../assets/iconos/eliminar.png" v-on:click="eliminarProducto" alt="">
            </div>
            <p id="nombreProducto">Queso</p>
            <div class="datosB">
              <p>Cantidad: </p>
              <h3>3</h3>
            </div>

          </div>
        </div>

        <div class="listas">
          <img src="../assets/leche.jpg" alt="">
          <div class="datosProducto">

            <div class="datosA">
              <p>Frutas</p>
              <img src="../assets/iconos/eliminar.png" v-on:click="eliminarProducto" alt="">
            </div>
            <p id="nombreProducto">Leche</p>
            <div class="datosB">
              <p>Cantidad: </p>
              <h3>3</h3>
            </div>

          </div>
        </div>


      </div>

    </div>

  </div>



</template>

<script>
import swal from 'sweetalert';
import eliminarProducto from "./notificaciones/eliminarProducto.vue";

export default {

    data: function() {
    return {
      contAlert: false
    }
  },
  components: { swal, eliminarProducto },
  methods:
  {
     eliminarProducto: function()
    {
      this.$store.state.eliminarProducto = true;
      console.log(this.$store.state.eliminarProducto);
    },
    productos: function()
    {
      location.href = "/productos";
    },
    pruebaAlert: function()
    {
      swal("Write something here:", {
        content: "input",
      })
      .then((value) => {
        swal(`You typed: ${value}`);
      });

    },
    abrirAlert:function()
    {
      this.contAlert = true;
    },
    cerrarAlert: function()
    {
      this.contAlert = false;
    },
    btnBlog:function()
    {
      document.getElementById('contMenuMovil').style.display = 'none';
       window.open('https://blog.mygirlweb.com/', '_blank');
    },
    btnDash:function()
    {
      location.href = "/listas";
      document.getElementById('contMenuMovil').style.display = 'none';
    },
    btnProfile:function()
    {
      document.getElementById('contMenuMovil').style.display = 'none';
    },
    btnMenu:function()
    {
      document.getElementById('contMenuMovil').style.display = 'flex';
    }
  },
  mounted: function()
  {
  }
}

</script>


<style lang="css" scoped>

.btnAlert
{
  background-color: red;
}
.principal
{
  width: 100%;
  margin-bottom: 90px;
}

.navMenu
{
  position: fixed;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 16px rgba(0,0,0,0.08);
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navMenu span
{
  font-size: 4.5vw;
  margin-left: 15px;
  color: #4E4B4B;
}

.btnAtras
{
  width: 20px;
  height: 20px;
  margin-left: 15px;
  background: url('../assets/iconos/right.png') no-repeat center;
  background-size: auto 100%;
  border: none;
  outline:none;
  cursor: pointer;
}

.btnHamburguesa
{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background: url('../assets/iconos/menu.png') no-repeat center;
  background-size: auto 100%;
  border: none;
  outline:none;
  cursor: pointer;
}

#contMenuMovil
{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
  padding: 4px;

  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.itemMenuMovil
{
  width: 100%;
  height: 33.3%;
  border: none;
  margin: 2px;
  background-color: #EFDDD4;
  outline:none;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.imgItemMenu
{
  width: 40px;
  height: 40px;
}

.imgItemMenu:nth-child(3)
{
  width: 20px;
  height: 20px;
}

.txtItemMenu
{
  margin: 0;
  letter-spacing: 2px;
  color: #fff;
  font-size: 4.8vw;
  font-weight: normal;
}

.navInferior
{
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 0;
  background-color: #fff;
  z-index: 2;

  display: flex;
  justify-content: space-around;
  align-items: center;
}



.btn
{
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  outline:none;
}

.btnCentro
{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #EFDDD4;
  border: none;
  cursor: pointer;
  outline:none;
}

#btnMarket
{
  background: url('../assets/iconos/trolley.png') no-repeat center;
  background-size: auto 80%;
  opacity: 0.65;
}

.contAlert
{
    position: fixed;
    width: 85%;
    height: 40%;
    top: 30%;
    left: 7.5%;
    border-radius: 4px;

    background-color: #fff;
    z-index: 3;
    box-shadow: 5px 5px 16px rgba(0,0,0,0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contAlert img
{
  width: 10px;
  height: 10px;
  margin: 20px 0 20px 0;
}

.contAlert p
{
  margin: 20px 0 0 0;
  font-size: 5.2vw;
  letter-spacing: 1px;
  color: #4E4B4B;
}

.contAlert input
{
  width: 70%;
  height: 40px;
  margin: 20px 0 20px 0;
  border: none;
  border-bottom: 1px solid #4E4B4B;
}

.contAlert button
{
  width: 70%;
  height: 40px;
  background-color: #EFDDD4;
  border: none;
  color: #4E4B4B;
  margin: 20px 0 20px 0;
  border-radius: 4px;
}


.titleProfile
{
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleProfile p
{
  margin: 15px;
  font-size: 4.5vw;
  color: #4E4B4B;
  letter-spacing: 1px;
}

.titleProfile button
{
  margin: 15px;
  background-color: #EFDDD4;
  padding: 3px 12px;
  border: none;
  color: #4E4B4B;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.08);
  font-size: 3vw;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
}

.contProfile
{
  width: 100%;
  padding: 20px 15px 20px 15px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}



.contListas
{
  width: 100%;
  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.listas
{
  width: 95%;
  height: 140px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}


.listas p
{
  margin: 0;
  margin-left: 15px;
  font-size: 4vw;
  color: #4E4B4B;
  letter-spacing: 1px;
}

.listas img
{
  width: 80px;
  height: 90px;
  border-radius: 15px;
  opacity: 0.75;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  cursor: pointer;
}


.datosProducto
{
  width: calc(100% - 100px);
  height: 65%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.datosProducto p
{
    margin: 0;
    font-size: 4vw;
    letter-spacing: 1px;
}

.datosA
{
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}


.datosA img
{
  box-shadow: none;
  width: 20px;
  height: 20px;
}

#nombreProducto
{
  font-size: 3.9vw;
  letter-spacing: 1px;
  font-weight: bold;
}

.datosB
{
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.datosB h3
{
  margin: 0;
  margin-left: 5px;
  font-size: 3.9vw;
  letter-spacing: 1px;
}


</style>
