<template lang="html">
  <div class="principal">

    <nav class="navMenu">
      <h2>DASHBOARD</h2>
      <button v-on:click="btnMenu" type="button" name="button"></button>
    </nav>

    
    <menuInferior></menuInferior>

    <div class="contKPI">

      <button class="KPI" type="button" name="button">
        <img class="imgKPI" src="../assets/iconos/noteW.png" alt="">
        <i class="lineKPI"></i>
        <div class="contTxtKPI">
          <p>20 pza</p>
        </div>
      </button>

      <button class="KPI" type="button" name="button">
        <img class="imgKPI" src="../assets/iconos/cartW.png" alt="">
        <i class="lineKPI"></i>
        <div class="contTxtKPI">
          <p>1 lista</p>
        </div>
      </button>

    </div>

    <div class="contTareas">
      <div class="contTxtTareas">
        <p>Últimas tareas pendientes</p>
      </div>

      <div class="contItemTareas">

        <button class="itemTarea" type="button" name="button">
          <img class="imgTarea" src="../assets/iconos/list.png" alt="">
          <p>Nombre</p>
        </button>

        <button class="itemTarea" type="button" name="button">
          <img class="imgTarea" src="../assets/iconos/list.png" alt="">
          <p>Nombre</p>
        </button>

        <button class="itemTarea" type="button" name="button">
          <img class="imgTarea" src="../assets/iconos/list.png" alt="">
          <p>Nombre</p>
        </button>

      </div>

    </div>

    <div class="contTareas">
      <div class="contTxtTareas">
        <p>Lista de compras pendiente</p>
      </div>

      <div class="contItemTareas">

        <button class="itemTarea" type="button" name="button">
          <img class="imgTarea" src="../assets/iconos/trolley.png" alt="">
          <p>Nombre</p>
        </button>

        <button class="itemTarea" type="button" name="button">
          <img class="imgTarea" src="../assets/iconos/trolley.png" alt="">
          <p>Nombre</p>
        </button>

        <button class="itemTarea" type="button" name="button">
          <img class="imgTarea" src="../assets/iconos/trolley.png" alt="">
          <p>Nombre</p>
        </button>

      </div>

    </div>

    <menuCompleto v-if="$store.state.menu"></menuCompleto>

  </div>

</template>

<script>
import menuCompleto from "./menus/menu.vue";
import menuInferior from "./menus/menuInferior.vue";

export default {

    data: function() {
    return {
    }
  },
  components: { menuInferior, menuCompleto},
  methods:
  {
    
    btnMenu:function()
    {
      this.$store.state.menu = true;
    }
  },
  mounted: function()
  {
  }
}

</script>

<style lang="css" scoped>

.principal
{
  width: 100%;
  margin-bottom: 90px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.navMenu
{
  position: fixed;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 16px rgba(0,0,0,0.08);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navMenu h2
{
  font-size: 4.5vw;
  margin-left: 15px;
  color: #4E4B4B;
  letter-spacing: 1px;
}

.navMenu button
{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background: url('../assets/iconos/menu.png') no-repeat center;
  background-size: auto 100%;
  border: none;
  outline:none;
  cursor: pointer;
}




.contKPI
{
  width: 100%;
  padding: 50px 15px 30px 15px;
  margin-top: 50px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}

.KPI
{
  width: 147px;
  height: 97px;
  background-color: #EFDDD4;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 5px 5px 16px rgba(0,0,0,0.16);
  outline:none;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgKPI
{
  width: 30px;
  height: 30px;
}

.lineKPI
{
  width: 50%;
  height: 1px;
  background-color: #fff;
  margin: 8px 0;
}

.contTxtKPI
{
  width: 70%;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.contTxtKPI p
{
  font-size: 4.5vw;
  color: #4E4B4B;
  letter-spacing: 1px;
}

.contTareas
{
  width: 100%;
}

.contTxtTareas
{
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contTxtTareas p
{
  font-size: 1.2em;
  color: #4E4B4B;
  margin-left: 20px;
  letter-spacing: 1px;
}

.contItemTareas
{
  width: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
}

.itemTarea
{
  width: 100%;
  height: 45px;
  border: none;
  border: 1px solid #EFDDD4;
  margin: 0 0 10px 0;
  background-color: #fff;
  border-radius: 3px;
  outline:none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.imgTarea
{
  width: auto;
  height: 20px;
  margin: 0 15px 0 15px;
}

.itemTarea p
{
  margin: 0;
  font-size: 1.2em;
  letter-spacing: 1px;
  color: #4E4B4B;
}

@media screen and (min-width: 768px) and (max-width: 1023px) 
{

  .principal
  {
    margin-bottom: 110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
  }
  .navMenu
  {
    height: 60px;
  }

  .navMenu h2 
  {
    font-size: 18px;
  }

  .navMenu button
  {
    width: 50px;
    height: 50px;
    background-size: auto 50%;
  }

  .KPI 
  {
    width: 170px;
    height: 120px;
  }

  .contTxtKPI
  {
    height: 35px;
  }

  .contTxtKPI p 
  {
    font-size: 1.3em;
  }

  .contTareas
  {
    width: 95%;
  }
}

@media screen and (min-width: 1024px) 
{
  .principal
  {
    width: 95%;
    margin-left: 5%;

  }

  .navMenu
  {
    position: fixed;
    width: 85%;
    height: 80px;
    left: 10%;
    padding: 0 20px;
    border-radius: 0 0 5px 5px;
  }

  .navMenu h2 
  {
    margin: 0;
    font-size: 15px;
  }

  #btnMenuM
  {
    width: 20px;
    height: 20px;
  }

  .contKPI
  {
    margin-top: 100px;
    padding: 50px 70px 30px 73px;
  }

  .contTxtKPI p
  {
    font-size: 1em;
  }
  .contTareas
  {
    width: 85%;
  }

  .contTxtKPI p
  {
    font-size: 1em;
  }

  .itemTarea p 
  {
    font-size: 1em;
    color: #4E4B4B;
  }
}
</style>
