<template lang="html">
  <div id="contenedor">
    <header class="cabeceraCont">
      <h2>MI AGENDA</h2>

      <button id="btnMenuM" type="button" v-on:click="btnMenu"></button>
    </header>

    <nuevaCategoria v-if="$store.state.nuevaCategoria"></nuevaCategoria>
    <nuevoRegistro v-if="$store.state.nuevoRegistro"></nuevoRegistro>
    <eliminarRegistro v-if="$store.state.eliminarRegistro"></eliminarRegistro>

    <menuInferior></menuInferior>

    <div class="categoriaPanel">
      Categoría

      <button id="btnAgregar" type="button" v-on:click="nuevaCategoria">Nueva categoría</button>
    </div>
    <div class="categoriaCont">
      <carousel
        :items="3"
        :loop="false"
        :nav="false"
        :dots="false"
        :margin="10"
        :responsiveRefreshRate="4"
      >
        <div class="categoriaItem">
          <p>Escuela</p>
        </div>
        <div class="categoriaItem">
          <p>Trabajo</p>
        </div>
        <div class="categoriaItem">
          <p>Casa</p>
        </div>
        <div class="categoriaItem">
          <p>Hobbies</p>
        </div>
      </carousel>
    </div>
    <div class="tipoPanel">Tipo</div>
    <div class="tipoCont">
      <carousel :items="3" :loop="false" :nav="false" :dots="false" :margin="10" :responsiveRefreshRate="3">
        <div class="tipoItem">
          <p>Evento</p>
        </div>
        <div class="tipoItem">
          <p>Tarea</p>
        </div>
        <div class="tipoItem">
          <p>Notas</p>
        </div>
      </carousel>
    </div>

    <div class="calendarioCont">
      <div class="btncalendarioCont">
        <p>Calendario</p>
        <button id="btnCalendario" type="button" v-on:click="nuevoRegistro">Nuevo registro</button>
      </div>
      

      <DraggableCal />
    </div>

    <div class="tareasCont">

      <div class="tareasItem">
        <div class="checkTask">
          <div id="uno" class="check" data-id="uno" v-on:click="btnChecked"></div>
        </div>
        <div class="nameTask">
          <p>Nombre</p>
        </div>
        <div class="deleteTask">
          <div class="delete">
            <img src="../assets/iconos/eliminar.png" v-on:click="eliminarRegistro" alt="" />
          </div>
        </div>
      </div>

      <div class="tareasItem">
        <div class="checkTask">
          <div id="dos" class="check" data-id="dos" v-on:click="btnChecked"></div>
        </div>
        <div class="nameTask">
          <p>Nombre</p>
        </div>
        <div class="deleteTask">
          <div class="delete">
            <img src="../assets/iconos/eliminar.png" v-on:click="eliminarRegistro" alt="" />
          </div>
        </div>
      </div>

      <div class="tareasItem">
        <div class="checkTask">
          <div id="tres" class="check" data-id="tres" v-on:click="btnChecked"></div>
        </div>
        <div class="nameTask">
          <p>Nombre</p>
        </div>
        <div class="deleteTask">
          <div class="delete">
            <img src="../assets/iconos/eliminar.png" v-on:click="eliminarRegistro" alt="" />
          </div>
        </div>
      </div>


    </div>

    <menuCompleto v-if="$store.state.menu"></menuCompleto>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import DraggableCal from "vue-draggable-cal";

import nuevaCategoria from "./notificaciones/nuevaCategoria.vue";
import nuevoRegistro from "./notificaciones/nuevoRegistro.vue";
import eliminarRegistro from "./notificaciones/eliminarRegistro.vue";

import menuCompleto from "./menus/menu.vue";
import menuInferior from "./menus/menuInferior.vue";

export default {
  components: { carousel, DraggableCal, nuevaCategoria, nuevoRegistro, menuInferior, eliminarRegistro, menuCompleto },

  data: function () {
    return {};
  },
  methods: {

    btnChecked: function(event)
    { 
      //console.log(event.target.attributes['data-id'].value);
      var id = event.target.attributes['data-id'].value; 
      document.getElementById(id).classList.add("checked");
      
    },    
    btnMenu: function()
    {
      this.$store.state.menu = true;
    },
    eliminarRegistro: function()
    {
      this.$store.state.eliminarRegistro = true;
      console.log(this.$store.state.eliminarRegistro);
    },
    nuevoRegistro: function()
    {
      this.$store.state.nuevoRegistro = true;
      console.log(this.$store.state.nuevoRegistro);
    },
    nuevaCategoria: function()
    {
      this.$store.state.nuevaCategoria = true;
      console.log(this.$store.state.nuevaCategoria);
    }
  },
  mounted() {
    
  },
};
</script>

<style lang="css" scoped>
  .cabeceraCont {
    position: fixed;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    z-index: 2;
    box-sizing: border-box;
  }
  .cabeceraCont h2 {
    margin: 0;
    font-size: 15px;
    letter-spacing: 1px;
  }
  #btnMenuM {
    width: 35px;
    height: 35px;
    background: url("../assets/menuM.png") no-repeat center;
    background-size: 18px 12px;
    border: none;
    cursor: pointer;
  }


  .categoriaPanel {
    width: 100%;
    padding: 70px 15px 20px 27px;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  #btnAgregar {
    padding: 3px 12px;
    background: #bad9d8;
    border-radius: 3px;
    box-shadow: 0 3px 6px #00000029;
    border: none;
    color: #4e4b4b;
    font-size: 3vw;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .categoriaCont {
    width: 87.22%;
    height: 19px;
    margin: 0 auto;
  }
  .categoriaItem {
    padding: 4px 10px;
    background: #efddd4;
    border-radius: 3px;
    box-shadow: 0 3px 6px #00000029;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .categoriaItem p {
    margin: 0;
    font-size: 3vw;
    letter-spacing: 1px;
  }

  .categoriaItem:hover {
    background: #bad9d8;
  }

  .tipoPanel {
    width: 100%;
    padding: 18px 0 20px 23px;
    letter-spacing: 1px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
  }
  .tipoCont {
    width: 87.22%;
    height: 19px;
    margin: 0 auto;
  }
  .tipoItem {
    padding: 4px 10px;
    background: #efddd4;
    border-radius: 3px;
    box-shadow: 0 3px 6px #00000029;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tipoItem p {
    margin: 0;
    font-size: 3vw;
    letter-spacing: 1px;
  }

  .tipoItem:hover {
    background: #bad9d8;
  }
  .calendarioCont {
    width: 100%;
    height: auto;
    margin: 36px 0 26px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .btncalendarioCont 
  {
    width: 88%;
    padding: 0px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btncalendarioCont p {
    margin: 0;
    letter-spacing: 1px;
  }

  #btnCalendario
  {
    padding: 3px 12px;
    background: #bad9d8;
    border-radius: 3px;
    box-shadow: 0 3px 6px #00000029;
    border: none;
    color: #4e4b4b;
    font-size: 3vw;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .tareasCont {
    width: 100%;
    height: auto;
    padding-bottom: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .tareasItem {
    width: 90%;
    height: 46.5px;
    border: 1px solid #efddd4;
    border-radius: 5px;
    margin-bottom: 15px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .checkTask,
  .deleteTask {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .check {
    width: 17px;
    height: 17px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    cursor: pointer;
  }

  .checked
  {
    border: none;
    background: url("../assets/iconos/check.png") no-repeat center;
    background-size: auto 100%;
  }

  .delete {
    width: 20px;
    height: 20px;
  }

  .delete img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .nameTask {
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .nameTask p {
    margin: 0;
    margin-left: 10px;
    font-size: 4vw;
  }

@media screen and (min-width: 768px) and (max-width: 1023px) 
{
  .cabeceraCont
  {
    height: 60px;
  }
  .cabeceraCont h2
  {
    font-size: 18px;
  }

  #btnMenuM
  {
    width: 50px;
    height: 50px;
    background-size: auto 50%;
  }

  .categoriaPanel
  {
    padding: 100px 15px 40px 27px;
    font-size: 1.2em;
  }

  #btnAgregar
  {
    padding: 6px 13px;
    font-size: 1.2em;
  }

  .categoriaCont
  {
    height: auto;
  }

  .categoriaItem p 
  {
    font-size: 1.2em;
  }

  .tipoPanel
  {
    font-size: 1.2em;
  }

  .tipoCont
  {
    height: auto;
  }

  .tipoItem p 
  {
    font-size: 1.2em;
  }

  .btncalendarioCont
  {
    width: 95%;
    padding: 10px 23px;
  }

  .btncalendarioCont p 
  {
    font-size: 1.2em;
  }

  #btnCalendario 
  {
    font-size: 1.2em;
    padding: 6px 20px;
  }

  .tareasCont{
    padding-bottom: 110px;
  }

  .nameTask p
  {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1024px) 
{
  #contenedor 
  {
    width: 85%;
    margin-left: 9%;
  }
  .cabeceraCont
  {
    width: 85%;
    height: 80px;
    border-radius: 0 0 5px 5px;
  }
  .categoriaPanel
  {
    padding: 110px 26px 40px 27px;
    font-size: 1em;
  }

  #btnAgregar
  {
    padding: 6px 13px;
    font-size: 1em;
  }

  .categoriaCont
  {
    height: auto;
  }

  .categoriaItem p 
  {
    font-size: 1em;
  }

  .tipoPanel
  {
    font-size: 1em;
  }

  .tipoCont
  {
    height: auto;
  }

  .calendarioCont
  {
    width: 99%;
  }

  .tipoItem p 
  {
    font-size: 1em;
  }

  .btncalendarioCont
  {
    width: 96%;
    padding: 10px 26px;
  }

  .btncalendarioCont p 
  {
    font-size: 1em;
  }

  #btnCalendario 
  {
    font-size: 1em;
    padding: 6px 15px;
  }

  .tareasCont{
    padding-bottom: 110px;
  }

  .tareasItem
  {
    width: 88%;
  }

  .nameTask p
  {
    font-size: 1em;
  }
}
</style>
