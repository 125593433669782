import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nuevaLista: false,
    eliminarLista: false,
    eliminarProducto: false,

    nuevaCategoria: false,
    nuevoRegistro: false,
    eliminarRegistro: false,
    menu: false 
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
