import Vue from 'vue'
import VueRouter from 'vue-router'
import dash from '@/components/dashboard.vue'
import profile from '@/components/profile.vue'
import market from '@/components/market.vue'
import listas from '@/components/listas.vue'
import planner from '@/components/planner.vue'
import detalleLista from '@/components/detalleLista.vue'
import productos from '@/components/productos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DASHBOARD',
    component: dash
  },
  {
    path: '/perfil',
    name: 'PROFILE',
    component: profile
  },
  {
    path: '/listas',
    name: 'MARKET',
    component: listas
  },
  {
    path: '/detalleLista',
    name: 'DETALLE LISTA',
    component: detalleLista
  },
  {
    path: '/productos',
    name: 'PRODUCTOS',
    component: productos
  },
  {
    path: '/planner',
    name: 'PLANNER',
    component: planner
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
