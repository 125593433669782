<template lang="html">
  <div class="">
  <div id="contMenuMovil" class="contMenuMovil">
      <button v-on:click="btnDash" class="itemMenuMovil" type="button" name="button">
        <img v-on:click="btnDash" class="imgItemMenu" src="../../assets/iconos/home.png" alt="">
        <p v-on:click="btnDash" class="txtItemMenu">Inicio</p>
        <img v-on:click="btnDash" class="imgItemMenu" src="../../assets/iconos/arrow.png" alt="">
      </button>

      <button v-on:click="btnProfile" class="itemMenuMovil" type="button" name="button">
        <img v-on:click="btnProfile" class="imgItemMenu" src="../../assets/iconos/userB.png" alt="">
        <p v-on:click="btnProfile" class="txtItemMenu">Mi perfil</p>
        <img v-on:click="btnProfile" class="imgItemMenu" src="../../assets/iconos/arrow.png" alt="">
      </button>

      <button v-on:click="btnBlog" class="itemMenuMovil" type="button" name="button">
        <img v-on:click="btnBlog" class="imgItemMenu" src="../../assets/iconos/blog.png" alt="">
        <p v-on:click="btnBlog" class="txtItemMenu">Blog</p>
        <img v-on:click="btnBlog" class="imgItemMenu" src="../../assets/iconos/arrow.png" alt="">
      </button>

      <!-- <button class="itemMenuMovil" type="button" name="button">
        <img class="imgItemMenu" src="../assets/iconos/noteW.png" alt="">
        <p class="txtItemMenu">Dashboard</p>
        <img class="imgItemMenu" src="../assets/iconos/arrow.png" alt="">
      </button> -->


    </div>
    
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  components: {},
  methods: {
      btnBlog:function()
    {
      this.$store.state.menu = false;
       window.open('https://blog.mygirlweb.com/', '_blank');
    },
    btnProfile:function()
    {
      this.$store.state.menu = false;
      location.href = "/perfil";
    },
    btnDash:function()
    {
      this.$store.state.menu = false;
      location.href = "/";
    }
  },

  mounted: function () {},
};
</script>

<style lang="css" scoped>
#contMenuMovil
{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 6;
  padding: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.itemMenuMovil
{
  width: 100%;
  height: 33.3%;
  border: none;
  margin: 2px;
  background-color: #EFDDD4;
  outline:none;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.imgItemMenu
{
  width: 40px;
  height: 40px;
}

.imgItemMenu:nth-child(3)
{
  width: 20px;
  height: 20px;
}

.txtItemMenu
{
  margin: 0;
  letter-spacing: 2px;
  color: #fff;
  font-size: 4.8vw;
  font-weight: normal;
}

@media screen and (min-width: 768px) and (max-width: 1023px) 
{
  #contMenuMovil
  {
    background-color: #EFDDD4;
  }
  .itemMenuMovil
  {
    width: 80%;
    border-bottom: 2px solid #fff;
  }

  .txtItemMenu
  {
    font-size: 3vw;
  }
}

@media screen and (min-width: 1024px) 
{
  #contMenuMovil
  {
    background-color: #EFDDD4;
  }
  .itemMenuMovil
  {
    width: 80%;
    border-bottom: 2px solid #fff;
  }

  .txtItemMenu
  {
    font-size: 2vw;
  }
}

</style>
