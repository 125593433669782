<template lang="html">
  <div class="">
    <nav class="navInferior">
      <button id="btnMarket" v-on:click="btnMarket" class="btn" type="button" name="button"></button>
      <button id="btnPlanner" v-on:click="btnPlanner" class="btn" type="button" name="button"></button>
      <button id="btnCentro" v-on:click="btnInicio" class="btnCentro" type="button" name="button"></button>
      <button class="btn" type="button" name="button"></button>
      <button class="btn" type="button" name="button"></button>
    </nav>

    <nav class="navInferiorDesk">

      <div class="itemMenu">
        <div id="btnCentro" v-on:click="btnInicio" class="btnCentro" type="button" name="button">
          <p>Inicio</p>  
        </div>
      </div>

      <div class="itemMenu">
        <div id="btnPlanner" v-on:click="btnPlanner" class="btn" type="button" name="button">
          <p>Agenda</p>  
        </div>
      </div>

      <div class="itemMenu">
        <div id="btnMarket" v-on:click="btnMarket" class="btn" type="button" name="button">
          <p>Listas</p>
        </div>

      </div>     
      
      
        
    </nav>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {};
    },
    components: {},
    methods: {
      btnInicio: function () {
        location.href = "/";
      },
      btnPlanner: function () {
        location.href = "/planner";
      },
      btnMarket: function () {
        location.href = "/listas";
      },
    },

    mounted: function () { },
  };
</script>

<style lang="css" scoped>

  .navInferiorDesk
  {
    display: none;
  }

  .navInferior {
    position: fixed;
    width: 100%;
    height: 70px;
    bottom: 0;
    left: 0;
    background-color: #fff;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .btn {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .btnCentro {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    outline: none;
  }

  #btnMarket {
    background: url("../../assets/iconos/trolley.png") no-repeat center;
    background-size: auto 80%;
    opacity: 0.65;
  }

  #btnPlanner {
    background: url("../../assets/iconos/list.png") no-repeat center;
    background-size: auto 80%;
  }

  #btnCentro {
    background: url("../../assets/iconos/home.png") no-repeat center;
    background-size: auto 40%;
    background-color: #efddd4;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

@media screen and (min-width: 768px) and (max-width: 1023px) 
{
  .navInferiorDesk
  {
    display: none;
  }
  .navInferior
  {
    height: 110px;
    left: 0;
  }

  .btn 
  {
    width: 40px;
    height: 40px;
  }

  .btnCentro
  {
    width: 70px;
    height: 70px;
  }
}

@media screen and (min-width: 1024px) 
{
  .navInferior
  {
    display: none;
  }
  .navInferiorDesk
  {
    position: fixed;
    width: 5%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #efddd4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    z-index: 3;
  }

  .itemMenu
  {
    width: 100%;
    height: 90px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #btnCentro
  {
    width: 100%;
    height: 90%;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .itemMenu p 
  {
    font-size: 0.6vw;
    color: #4e4b4b;
    margin: 0;
    letter-spacing: 1px;
  }

  .btn 
  {
    width: 100%;
    height: 90%;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
  }

  #btnCentro {
    background: url("../../assets/iconos/home.png") no-repeat center;
    background-size: auto 35%;
    background-color: #efddd4;
    cursor: pointer;
  }


  #btnMarket {
    background: url("../../assets/iconos/trolleyB.png") no-repeat center;
    background-size: auto 35%;
    opacity: 1;
    cursor: pointer;
  }

  #btnPlanner {
    background: url("../../assets/iconos/listB.png") no-repeat center;
    background-size: auto 35%;
  }

}
</style>