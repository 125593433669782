<template lang="html">
    <div id="contenedor">

      <nav class="navMenu">
        <button v-on:click="btnDash" class="btnAtras" type="button" name="button"></button>
        <!-- <button class="btnHamburguesa" v-on:click="btnMenu" type="button" name="button"></button> -->
      </nav>

        <div class="categoriaPanel">
            Categoría

            <div class="contBuscar">
              <input type="text" name="" value="">
              <img src="../assets/iconos/loupe.png" alt="">
            </div>

        </div>
        <div class="categoriaCont">
            <carousel :items="3" :loop="false" :nav="false" :dots="false" :margin="10">
                <div class="categoriaItem">
                  <p>Frutas</p>
                </div>
                <div class="categoriaItem">
                  <p>Verduras</p>
                </div>
                <div class="categoriaItem">
                  <p>Lacteos</p>
                </div>
                <div class="categoriaItem">
                  <p>Otro</p>

                </div>

            </carousel>
        </div>

        <div class="tipoPanel">
            Productos
        </div>

        <div class="contProductos">

          <div class="itemProducto">

            <img src="../assets/aguacate.jpg" alt="">
            <div class="datos">
              <p id="producto">Aguacate</p>
              <div class="cantidad">
                <p>Cantidad</p>
                <input type="number" name="" value="3">
              </div>
            </div>

            <button type="button" name="button" v-on:click="alertProducto"></button>

          </div>

          <div class="itemProducto">

            <img src="../assets/tomate.jpg" alt="">
            <div class="datos">
              <p id="producto">Tomate</p>
              <div class="cantidad">
                <p>Cantidad</p>
                <input type="number" name="" value="1">
              </div>
            </div>

            <button type="button" name="button" v-on:click="alertProducto"></button>

          </div>

          <div class="itemProducto">

            <img src="../assets/queso.jpg" alt="">
            <div class="datos">
              <p id="producto">Queso</p>
              <div class="cantidad">
                <p>Cantidad</p>
                <input type="number" name="" value="3">
              </div>
            </div>

            <button type="button" name="button" v-on:click="alertProducto"></button>

          </div>

          <div class="itemProducto">

            <img src="../assets/leche.jpg" alt="">
            <div class="datos">
              <p id="producto">Leche</p>
              <div class="cantidad">
                <p>Cantidad</p>
                <input type="number" name="" value="3">
              </div>
            </div>

            <button type="button" name="button" v-on:click="alertProducto"></button>

          </div>

          <div class="itemProducto">

            <img src="../assets/leche.jpg" alt="">
            <div class="datos">
              <p id="producto">Leche</p>
              <div class="cantidad">
                <p>Cantidad</p>
                <input type="number" name="" value="3">
              </div>
            </div>

            <button type="button" name="button" v-on:click="alertProducto"></button>

          </div>

          <div class="itemProducto">

            <img src="../assets/leche.jpg" alt="">
            <div class="datos">
              <p id="producto">Leche</p>
              <div class="cantidad">
                <p>Cantidad</p>
                <input type="number" name="" value="3">
              </div>
            </div>

            <button type="button" name="button" v-on:click="alertProducto"></button>

          </div>

        </div>


    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import DraggableCal from 'vue-draggable-cal';

export default {

    components: { carousel, DraggableCal },

    data: function() {
        return {

        }
    },
    methods:
    {
      alertProducto: function()
      {
        alert('El producto se ha agregado a tu lista')
      },
      btnDash: function()
      {
        location.href = "detalleLista";
      }
    },
    mounted: function()
    {
    }
}
</script>

<style lang="css" scoped>
.navMenu
{
  position: fixed;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 16px rgba(0,0,0,0.08);
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navMenu span
{
  font-size: 4.5vw;
  margin-left: 15px;
  color: #4E4B4B;
}

.btnAtras
{
  width: 20px;
  height: 20px;
  margin-left: 15px;
  background: url('../assets/iconos/right.png') no-repeat center;
  background-size: auto 100%;
  border: none;
  outline:none;
  cursor: pointer;
}

.btnHamburguesa
{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background: url('../assets/iconos/menu.png') no-repeat center;
  background-size: auto 100%;
  border: none;
  outline:none;
  cursor: pointer;
}


.categoriaPanel
{
    width: 100%;
    padding: 7px 15px 20px 27px;
    letter-spacing: 1px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

}
.contBuscar
{
  width: 30%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contBuscar input
{
  width: calc(100% - 25px);
  margin-right: 5px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.contBuscar img
{
  width: 12px;
  height: 12px;
}


#btnAgregar
{
    padding: 3px 12px;
    background: #EFDDD4;
    border-radius: 3px;
    box-shadow: 0 3px 6px #00000029;
    border: none;
    color: #4E4B4B;
    font-size: 3vw;
    letter-spacing: 1px;
}
.categoriaCont
{
    width: 87.22%;
    height: 19px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.categoriaItem
{
    padding: 4px 10px;
    background: #EFDDD4;
    border-radius: 3px;
    box-shadow: 0 3px 6px #00000029;

    display: flex;
    align-items: center;
    justify-content: center;
}

.categoriaItem p
{
  margin: 0;
  font-size: 3vw;
  letter-spacing: 1px;
}

.categoriaItem:hover
{
  background: #BAD9D8;
}

.tipoPanel
{
    width: 100%;
    padding: 18px 0 10px 23px;
    letter-spacing: 1px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}

.contProductos
{
  width: 100%;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.itemProducto
{
  width: 95%;
  height: 90px;
  border-bottom: 1px solid #ccc;
  margin: 8px 0 5px 0;


  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemProducto img
{
  width: 56px;
  height: 63px;
  border-radius: 15px;
  opacity: 0.75;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
}

.datos
{
  width: calc(100% - 120px);
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}


#producto
{
    margin: 0;
    margin-bottom: 3px;
    font-size: 4vw;
    letter-spacing: 1px;
    font-weight: bold;
}


.cantidad
{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 5px;
}

.cantidad p
{
  margin: 0;
  letter-spacing: 1px;
}

.cantidad input
{
  width: 30%;
  margin-left: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  text-align: center;
}

.itemProducto button
{
  width: 26px;
  height: 26px;
  border: none;
  border-radius: 7px;
  background-color: #fff;
  background: url("../assets/iconos/add.png") no-repeat center;
  background-size: auto 40%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.itemProducto button:hover
{
  background-color: #BAD9D8;
  box-shadow: none;
}

</style>
