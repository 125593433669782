<template lang="html">
  <div class="principal">
    <nav class="navMenu">
      <button
        v-on:click="btnDash"
        class="btnAtras"
        type="button"
        name="button"
      ></button>
      <button
        class="btnHamburguesa"
        v-on:click="btnMenu"
        type="button"
        name="button"
      ></button>
    </nav>

    <nuevaLista v-if="$store.state.nuevaLista"></nuevaLista>
    <eliminarLista v-if="$store.state.eliminarLista"></eliminarLista>

    
    <div class="titleProfile">
      <p>MIS LISTAS</p>
      <button type="button" name="button" v-on:click="pruebaAlert">
        Agregar
      </button>
    </div>

    <div class="contProfile">
      <div class="contListas">
        <div class="listas">
          <p v-on:click="detalleLista" >Supermercado</p>
          <img src="../assets/iconos/eliminarB.png" alt="" v-on:click="eliminarLista" />
        </div>

        <div class="listas">
          <p v-on:click="detalleLista" >Supermercado</p>
          <img src="../assets/iconos/eliminarB.png" alt="" v-on:click="eliminarLista" />
        </div>

        <div class="listas">
          <p v-on:click="detalleLista" >Supermercado</p>
          <img src="../assets/iconos/eliminarB.png" alt="" v-on:click="eliminarLista" />
        </div>
      </div>
    </div>

    <menuCompleto v-if="$store.state.menu"></menuCompleto>

  </div>
</template>

<script>
import swal from "sweetalert";
import nuevaLista from "./notificaciones/nuevaLista.vue";
import eliminarLista from "./notificaciones/eliminarLista.vue";

import menuCompleto from "./menus/menu.vue";

export default {
  data: function () {
    return {
      contAlert: false
    };
  },
  components: { swal, nuevaLista, eliminarLista, menuCompleto},
  methods: {
    btnPlanner: function () {
      location.href = "/planner";
    },
    btnMarket: function () {},
    detalleLista: function () {
      location.href = "/detalleLista";
    },
    /*pruebaAlert: function () {
      swal("Write something here:", {
        content: "input",
      }).then((value) => {
        swal(`You typed: ${value}`);
      });
    },*/
    pruebaAlert: function () {
      
      this.$store.state.nuevaLista = true;
      console.log(this.$store.state.nuevaLista);
    
    },
    eliminarLista: function()
    {
      this.$store.state.eliminarLista = true;
      console.log(this.$store.state.eliminarLista);
    },
    abrirAlert: function () {
      this.contAlert = true;
    },
    cerrarAlert: function () {
      this.contAlert = false;
    },
    btnBlog: function () {
      document.getElementById("contMenuMovil").style.display = "none";
      window.open("https://blog.mygirlweb.com/", "_blank");
    },
    btnDash: function () {
      location.href = "/";
      document.getElementById("contMenuMovil").style.display = "none";
    },
    btnProfile: function () {
      document.getElementById("contMenuMovil").style.display = "none";
    },
    btnMenu: function () {
      this.$store.state.menu = true;
    },
  },
  mounted: function () {},
};
</script>

<style lang="css" scoped>
.btnAlert {
  background-color: red;
}
.principal {
  width: 100%;
  margin-bottom: 90px;
}

.navMenu {
  position: fixed;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.08);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navMenu span {
  font-size: 4.5vw;
  margin-left: 15px;
  color: #4e4b4b;
}

.btnAtras {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  background: url("../assets/iconos/right.png") no-repeat center;
  background-size: auto 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.btnHamburguesa {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background: url("../assets/iconos/menu.png") no-repeat center;
  background-size: auto 100%;
  border: none;
  outline: none;
  cursor: pointer;
}





.titleProfile {
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleProfile p {
  margin: 15px;
  font-size: 4.5vw;
  color: #4e4b4b;
  letter-spacing: 1px;
}

.titleProfile button {
  margin: 15px;
  background-color: #efddd4;
  padding: 3px 12px;
  border: none;
  color: #4e4b4b;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  font-size: 3vw;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
}

.contProfile {
  width: 100%;
  padding: 20px 15px 20px 15px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.contListas {
  width: 100%;
}

.listas {
  width: 100%;
  height: 55px;
  border-radius: 8px;
  background-color: #efddd4;
  margin-bottom: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listas:hover {
  background-color: #bad9d8;
}

.listas p {
  margin: 0;
  margin-left: 15px;
  font-size: 4vw;
  color: #4e4b4b;
  letter-spacing: 1px;
}

.listas img {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  cursor: pointer;
}
</style>
